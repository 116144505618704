import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyCNKg8rEwwjzBAypSAJMaTT_AWSipeW4I0",
  authDomain: "richardsonstreetneighbourschat.firebaseapp.com",
  databaseURL: "https://richardsonstreetneighbourschat.firebaseio.com",
  projectId: "richardsonstreetneighbourschat",
  storageBucket: "richardsonstreetneighbourschat.appspot.com",
  messagingSenderId: "1086515014867",
  appId: "1:1086515014867:web:f51775082f500b85ad7826",
  measurementId: "G-3T9EVXM14T"
};

  firebase.initializeApp(firebaseConfig);
  export const auth = firebase.auth;
  export const db = firebase.database();