import React, { Component } from "react";
import Header from "../components/Header";
import Chats from "../components/Chats";
import Profiles from "../components/Profiles";
import ProfileForm from "../components/ProfileForm";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { auth } from "../services/firebase";
import { db } from "../services/firebase";

class Chat extends Component {
  state = {
    currentUserDetails: {
      currentUserId: auth().currentUser.uid,
      currentUserDisplayName: ''
    },
    profilesList: [],
    selectedProfileId: '',
    readError: null,
    writeError: null,
    loadingProfiles: false,
    isPaneOpen: false,
    isPaneOpenLeft: false
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingProfiles: true });

    try {
      await db.ref("profiles").on("value", snapshot => {
        let profilesList = [];
        // console.log('getting profiles list....');
        snapshot.forEach((snap) => {

          let currentSnap = [];

          currentSnap = snap.val();
          currentSnap['key'] = snap.key;

          profilesList.push(currentSnap);

          if (currentSnap.uid === this.state.currentUserDetails.currentUserId) {
            let currentUserDetails = {
              currentUserId: auth().currentUser.uid,
              currentUserDisplayName: currentSnap.display_name
            }
            this.setState({ currentUserDetails })

          }
        });
        let currentUserProfile = profilesList.filter(profile => profile.uid.includes(this.state.currentUserDetails.currentUserId));
        currentUserProfile[0]['current_user'] = true;
        profilesList = profilesList.filter(profile => !profile.uid.includes(this.state.currentUserDetails.currentUserId));

        profilesList = currentUserProfile.concat(profilesList);

        profilesList.sort(function (a, b) { return a.first_name - b.first_name })
        this.setState({ profilesList });
        this.setState({ loadingProfiles: false });
      });
    } catch (error) {
      this.setState({ readError: error.message, loadingProfiles: false });
    }
  }

  handleProfileButtonClick = (selectedProfileId) => {
    //console.log('Profile Button clicked...', selectedProfileId);

    this.setState({ selectedProfileId: selectedProfileId, isPaneOpen: true });
  }

  render() {

    return (
      <div>
        <Header />
        <div className="row">
          <div className="col chat-section">
            <Chats currentUserDetails={this.state.currentUserDetails} profilesList={this.state.profilesList} />
          </div>
          <div className="col profiles-section">
            <Profiles profilesList={this.state.profilesList} onProfileButtonClick={this.handleProfileButtonClick} />
          </div>
          <SlidingPane
            className="contact-details"
            overlayClassName="contact-details-sliding-pane"
            isOpen={this.state.isPaneOpen}
            title="Contact Details"
            subtitle=""
            width="85%"
            onRequestClose={() => {
              this.setState({ isPaneOpen: false });
            }}
          >
            <ProfileForm selectedProfileId={this.state.selectedProfileId} currentUserId={auth().currentUser.uid} />
          </SlidingPane>
        </div>
      </div>

    );
  }
}

export default Chat;

