import React, { Component } from "react";
import { db } from "../services/firebase";

export default class Chats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      profilesList: this.props.profilesList,
      chats: [],
      content: '',
      readError: null,
      writeError: null,
      loadingChats: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingChats: true });
    const chatArea = this.myRef.current;

    try {
      await db.ref("chats").on("value", snapshot => {
        let chats = [];
        let profilesList = this.props.profilesList;
        snapshot.forEach((snap) => {

          let currentSnap = snap.val();

          let tempProfile = profilesList.filter(profile => profile.uid.includes(currentSnap.uid));

          currentSnap['display_name'] = tempProfile[0].display_name;

          chats.push(currentSnap);

        });
        //console.log(chats);
        chats.sort(function (a, b) { return a.timestamp - b.timestamp })

        this.setState({ chats });
        chatArea.scrollBy(0, chatArea.scrollHeight);
        this.setState({ loadingChats: false });
      });
    } catch (error) {
      this.setState({ readError: error.message, loadingChats: false });
    }
  }

  handleChange(event) {
    this.setState({
      content: event.target.value
    });
  }

  async handleSubmit(event) {

    event.preventDefault();
    this.setState({ writeError: null });
    const chatArea = this.myRef.current;
    console.log(this.state.content, Date.now(), this.props.currentUserDetails.currentUserId);
    try {


      await db.ref("chats").push({
        content: this.state.content,
        timestamp: Date.now(),
        uid: this.props.currentUserDetails.currentUserId
      });
      this.setState({ content: '', chats: this.state.chats });
      chatArea.scrollBy(0, chatArea.scrollHeight);
    } catch (error) {
      this.setState({ writeError: error.message });
    }
    console.log('submit ');
  }

  formatTime(timestamp) {
    const d = new Date(timestamp);
    const time = `${d.getDate()}/${(d.getMonth() + 1)}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    return time;
  }

  render() {
    return (
      <div>
        <div className="chat-area" ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingChats ? <div className="spinner-border text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div> : ""}
          {/* chat area */}
          {this.state.chats.map(chat => {
            return <p key={chat.timestamp} className={"chat-bubble " + (this.props.currentUserDetails.currentUserId === chat.uid ? "current-user" : "")}>
              {chat.content}
              <br />
              <span className="chat-time float-right">{(this.props.currentUserDetails.currentUserId === chat.uid ? "Me" : chat.display_name)} ({this.formatTime(chat.timestamp)})</span>
            </p>
          })}
        </div>
        <div className="message-form mx-3 my-1">
          <form onSubmit={this.handleSubmit} className="">
            <textarea className="form-control" name="content" onChange={this.handleChange} value={this.state.content}></textarea>
            {this.state.error ? <p className="text-danger">{this.state.error}</p> : null}
            <button type="submit" className="btn btn-submit px-5 mt-4">Send</button>
          </form>

          <small>Logged in as: <strong className="text-info">{this.props.currentUserDetails.currentUserDisplayName}</strong></small>
        </div>

      </div>

    );
  }
}
