import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import Home from "./pages/Home";
//import MainApp from "./pages/Mainapp";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { auth } from "./services/firebase";
import { db } from "./services/firebase";
import './styles.css';
import Chat from "./pages/Chat";

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
            <Redirect to="/chat-app" />
          )
      }
    />
  );
}


function createNewProfile(user) {
  // console.log(user);

  db.ref('/profiles').orderByChild('uid')
    .equalTo(user.uid)
    .once('value')
    .then(function (snapshot) {

      if (snapshot.val() === null) {
        try {
          let newProfile = {
            display_name: user.displayName,
            mobile_phone: user.phoneNumber,
            email_address: user.email,
            address: '',
            profile_complete: false,
            uid: user.uid
          };
          db.ref("/profiles").push(newProfile);

        } catch (error) {
          //this.setState({ readError: error.message, loadingProfiles: false });
        }

      }
      //console.log(snapshot.val());
    })
    .catch(function (next) {
      console.log(next);
    });



}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true
    };
  }




  componentDidMount() {
    auth().onAuthStateChanged(user => {
      if (user) {

        createNewProfile(user);

        this.setState({
          authenticated: true,
          loading: false
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false
        });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute
              path="/chat-app"
              authenticated={this.state.authenticated}
              component={Chat}
            />
            <PublicRoute
              path="/signup"
              authenticated={this.state.authenticated}
              component={Signup}
            />
            <PublicRoute
              path="/login"
              authenticated={this.state.authenticated}
              component={Login}
            />
          </Switch>
        </Router>
      );
  }
}

export default App;
