import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Button, NavDropdown } from "react-bootstrap";
import { auth } from '../services/firebase';

function Header() {
  return (
    <header>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">Richardson Street Chat</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {auth().currentUser
              ? <div className="navbar-nav">
                <Nav.Link className="nav-item btn btn-primary mr-3" href="#" onClick={() => auth().signOut()}>Logout</Nav.Link>
              </div>
              : <div className="navbar-nav">
                <Nav.Link className="nav-item nav-link mr-3" href="/login">Sign In</Nav.Link>
                <Nav.Link className="nav-item nav-link mr-3" href="/signup">Sign Up</Nav.Link>
              </div>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    </header>
  );
}

export default Header;

/*
 <nav className="navbar navbar-expand-sm fixed-top navbar-light bg-light">
        <Link className="navbar-brand" to="/"></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          {auth().currentUser
            ? <div className="navbar-nav">
              <Link className="nav-item nav-link mr-3" to="/chat-app">Profile</Link>
              <Link className="nav-item btn btn-primary mr-3" onClick={() => auth().signOut()}>Logout</Link>
            </div>
            : <div className="navbar-nav">
              <Link className="nav-item nav-link mr-3" to="/login">Sign In</Link>
              <Link className="nav-item nav-link mr-3" to="/signup">Sign Up</Link>
            </div>}
        </div>
      </nav>
*/