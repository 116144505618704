import React, { Component } from "react";

export default class Profiles extends Component {

  render() {
    return (
      <div>
        <div className="profiles-area" ref={this.myRef}>
          {this.props.profilesList.map(profile => {
            return <div key={profile.key}><button key={profile.key} className={"profile-item " + (profile.current_user ? "btn-primary" : "btn-info") + " btn btn-sm"} onClick={() => this.props.onProfileButtonClick(profile.key)}>
              {(profile.display_name === "" ? "Profile Not Completed" : profile.display_name)}
              {(!profile.profile_complete ? " <i>(New contact!)</i>" : "")}
            </button>
            </div>
          })}
          <div className="small mt-3 text-justify">View more details for each contact or edit your own profile by selecting profile above</div>
        </div>
      </div>
    );
  }
}
